import React from 'react';
import { shape, string } from 'prop-types';
import { LazyHydrate as Hydrator } from 'nordic/lazy';
import View from '../view';

// Head Section
import CompatsWidgetSticky from '../../features/compats-widget-sticky/compats-widget-sticky.mobile';

import CompatsWidget from '../../features/compats-widget/compats-widget.desktop';
import CPG from '../../features/cpg/header-cpg.mobile';
import Onboarding from '../../features/onboarding/onboarding.mobile';
import ItemStatusHeadCompats from '../../features/item-status/head.compats.mobile';
import ItemStatusMessageHead from '../../features/item-status/head';
import RecommendationsCarouselFreeHorizontal from '../../features/recommendations/carousel-free-horizontal';

// Hero
import Header from '../../features/header/header.mobile';

import Gallery from '../../features/gallery/gallery.mobile';
import HeaderTopBrand from '../../features/header-top-brand/header-top-brand.mobile';
import HeaderBrandLogo from '../../features/header-brand-logo/header-brand-logo.mobile';
import Variations from '../../features/variations/variations.mobile';
import VariationsBox from '../../features/variations/variations-box.mobile';
import ApparealSizeSpec from '../../features/appareal-size-spec/apparel-size-spec.mobile';
import CardWithThumbnails from '../../features/card-with-thumbnails/card-with-thumbnails';

// Short Description
import Price from '../../features/price/price.mobile';

import Highlights from '../../features/highlights/highlights';
import Promotions from '../../features/promotions/promotions';
import PromotionsBottom from '../../features/promotions-bottom';
import PromotionTop from '../../features/promotions-top';
import PaymentSummary from '../../features/payment-summary/payment-summary.mobile';
import CpgSummary from '../../features/cpg/summary.mobile';
import CbtSummary from '../../../../components/cbt/cbt-summary.mobile';
import CouponSummary from '../../features/coupon-summary/coupon-summary.mobile';
import ShippingSummary from '../../features/shipping-summary/shipping-summary.mobile';
import SpecialShippingSummary from '../../features/special-shipping-summary/special-shipping-summary.mobile';
import SecondShippingSummary from '../../features/second-shipping-summary/second-shipping-summary.mobile';
import PickUpSummary from '../../features/pick-up-summary/pick-up-summary.mobile';
import CbtTaxesSummary from '../../../../components/cbt-taxes/cbt-taxes-summary.mobile';
import CbtTaxesFcUsSummary from '../../../../components/cbt-taxes-fc-us/cbt-taxes-fc-us-summary.mobile';
import ReturnsSummary from '../../features/returns-summary/returns-summary.mobile';
import MeliplusLabel from '../../features/meliplus-label/meliplus-label';
import MeliplusSummary from '../../features/meliplus-summary/meliplus-summary.mobile';
import UpsellingMeliplusSummary from '../../features/upselling-meliplus-summary/upselling-meliplus-summary.mobile';
import UpsellingMeliplusCashback from '../../features/upselling-meliplus-cashback/upselling-meliplus-cashback.mobile';
import ItemStatusShortDescription from '../../features/item-status/short-description';
import Seller from '../../features/seller/seller.mobile';
import GenericBanner from '../../features/generic-banner/generic-banner.mobile';
import GenericBannerPrice from '../../features/generic-banner-price/generic-banner.mobile';
import GenericBannerInstallments from '../../features/generic-banner-installments/generic-banner.mobile';
import SellerInfo from '../../features/seller-info/seller-info.mobile';
import Reputation from '../../features/seller-reputation-info';
import SellerData from '../../features/seller-data';
import SellerExperiment from '../../../vpp/features/seller-info/seller-experiment.mobile';
import MerchEcosystem from '../../features/merch-ecosystem/merch-ecosystem.mobile';
import StockInformation from '../../features/stock-information/stock-information.mobile';
import FulfillmentInformation from '../../features/fulfillment-information/fulfillment-information';
import TopAvailableQuantitySummary from '../../features/top-available-quantity-summary/top-available-quantity-summary.mobile';
import BulkSaleCalculator from '../../features/calculator/calculator';
import AvailableQuantity from '../../features/available-quantity/available-quantity.mobile';
import PaypalTooltip from '../../features/tooltips/paypal-tooltip';
import MainActions from '../../features/main-actions/main-actions.mobile';
import CartActions from '../../features/cart-actions/cart-actions.mobile';
import CreditsTooltip from '../../features/tooltips/credits-tooltip';
import ShippingCartBenefitsTooltip from '../../features/tooltips/shipping-cart-benefits-tooltip';
import LoyaltyFreeShippingTooltip from '../../features/tooltips/loyalty-tooltip';
import BuyBenefits from '../../features/buy-benefits/buy-benefits.mobile';
import GiftRegistry from '../../features/gift-registry';
import GroupedShareBookmark from '../../features/grouped-share-bookmark';
import PricePerQuantityShortcuts from '../../features/price-per-quantity-shortcuts';
import InstallationService from '../../features/instalation-service';
import ReturnsNotificationBottom from '../../features/returns-notification-bottom/returns-notification-bottom.mobile';
import ReturnsNotificationUp from '../../features/returns-notification-up/returns-notification-up.mobile';
import CollapsableCardMeliPlus from '../../features/collapsable-card-meliplus/collapsable-card-meliplus';
import ProductSuggestions from '../../features/product-suggestions';

// Recommendations
import RecommendationsListDouble from '../../features/recommendations/list-double';

import RecommendationsCarouselFree from '../../features/recommendations/carousel-free';
import RecommendationsCarouselByType from '../../features/recommendations/carousel-byType';
import RecommendationsCombos from '../../../../components/combos/combos.mobile';

// Features, Description, Returns & Box Components
import AllFeatures from '../../features/all-features/all-features.mobile';

import HighlightedSpecsAttrs from '../../features/highlighted-specs/highlighted-specs-attrs.mobile';
import HighlightedSpecsAttrsSwap from '../../features/highlighted-specs-swap/highlighted-specs-attrs.mobile';
import HighlightedSpecsAttrsThirdSwap from '../../features/highlighted-specs-third-swap/highlighted-specs-attrs.mobile';
import Description from '../../features/description/description.mobile';
import Returns from '../../features/returns/returns.mobile';
import Warranty from '../../features/warranty/warranty.mobile';
import ApparelSizeSpecsPreview from '../../features/apparel-size-specs-preview/apparel-size-specs-preview.mobile';

// Payment Methods, Questions, Reviews
import PaymentMethods from '../../features/payment-methods/payment-methods.mobile';

import Questions from '../../features/questions/questions.mobile';
import QADB from '../../features/qadb';
import Reviews from '../../features/reviews/reviews.mobile';
import ReviewsCapability from '../../../../components/reviews-capability/reviews.mobile';
import OfficialStores from '../../features/official-stores/official-stores.mobile';
import BillboardAdnUp from '../../features/billboard-adn/billboard-adn-up';

// Content Bottom
import Denounce from '../../features/denounce';
import FullscreenAd from '../../features/fullscreen-ad';
import RelatedSearches from '../../features/related-searches';
import Breadcrumb from '../../features/breadcrumb';
import MoqInformation from '../../features/moq-information/moq-information.mobile';
import BillboardAdn from '../../features/billboard-adn/billboard-adn-bottom';
import Grid from '../../../../components/grid';
import ExtendedMenu from '../../../vpp/features/extended-menu/extended-menu.mobile';

// Preload Iframe
import PreloadIframe from '../../features/preload-iframe/preload-iframe';

// On demand Iframe
import OnDemandIframe from '../../features/on-demand-iframe/on-demand-iframe';

// Exit event
import ExitPopupWebMobile from '../../features/exit-popup-web-mobile/exit-popup-web-mobile';

import Snackbar from '../../features/snackbar';
import DynamicComponentWrapper from '../../features/dynamic-component-wrapper';
import renderSwapComponent from '../../features/dynamic-component-wrapper/render-swap-component';

// Dynamic Spot Middle
import { spotMiddleMobileRender } from '../../../../utils/spotMiddleRenders';

const MeliDeliveryDaySummary = () => (
  <div className="mb-12">
    <MeliplusSummary />
    <CardWithThumbnails className="mt-4 mb-20 ui-pdp-generic-summary" />
  </div>
);

// Dynamic Shipping Wrapper (for order swap)
const renderTopShipping = renderSwapComponent('meliplus_summary', <MeliDeliveryDaySummary />, <ShippingSummary />);

const CoreMobile = props => {
  const {
    initialState: {
      baseUrl,
      components: { reviews_capability_v3 },
    },
    country,
  } = props;
  const { position = 'a' } = reviews_capability_v3 ?? {};

  return (
    <View {...props} vertical="core">
      <div id="loading" />
      <ExitPopupWebMobile url={baseUrl} />
      <Hydrator whenIdle>
        <OnDemandIframe />
      </Hydrator>
      <Hydrator whenIdle>
        <PreloadIframe />
      </Hydrator>
      <Grid.Container className="ui-pdp-container--top">
        {/* Head Section */}
        <Hydrator whenVisible>
          <CompatsWidgetSticky />
          <ItemStatusHeadCompats />
        </Hydrator>
        <Onboarding />
        <Hydrator whenVisible>
          <ProductSuggestions />
        </Hydrator>

        <CPG />
        <Hydrator whenVisible>
          <ItemStatusMessageHead />
        </Hydrator>
        <Hydrator whenIdle>
          <RecommendationsCarouselFreeHorizontal
            featureName="carousel_cheaper"
            className="ui-pdp-carousel-cheaper"
            lazy={false}
            preload
            fetchPriority="high"
            criticalImages={1}
          />
        </Hydrator>
        <Hydrator whenIdle>
          <RecommendationsCarouselFree
            featureName="carousel_v2p_not_active_item"
            className="ui-pdp-carousel-not-active"
            lazy={false}
            preload
            fetchPriority="high"
            criticalImages={1}
          />
        </Hydrator>
        <Hydrator whenIdle>
          <RecommendationsCarouselFreeHorizontal
            featureName="carousel_neighbour_top"
            className="ui-pdp-carousel-cheaper"
            lazy={false}
            preload
            fetchPriority="high"
            criticalImages={1}
          />
        </Hydrator>
        {/* Hero */}
        <Hydrator whenIdle>
          <HeaderBrandLogo />
        </Hydrator>
        <Hydrator whenIdle>
          <HeaderTopBrand />
        </Hydrator>
        <Hydrator whenIdle>
          <Header>
            <Highlights className="ui-pdp-highlights" mobile />
          </Header>
        </Hydrator>
        <Gallery />
        <Hydrator whenVisible>
          <CompatsWidget position="below_gallery" />
        </Hydrator>
        <Hydrator whenVisible>
          <VariationsBox />
        </Hydrator>
        <Hydrator whenVisible>
          <ApparealSizeSpec className="mt-16" />
        </Hydrator>
        <Hydrator whenVisible>
          <Variations />
        </Hydrator>

        {/* Short Description */}
        <Hydrator whenIdle>
          <PromotionTop className="ui-pdp-highlights" mobile />
        </Hydrator>
        <Hydrator whenVisible>
          <CollapsableCardMeliPlus />
        </Hydrator>
        <Hydrator whenVisible>
          {/* TODO-ERRORBOUNDARY: Esto es una lib --vpp-fe-components-- */}
          <Price />
        </Hydrator>
        <Hydrator whenVisible>
          <PricePerQuantityShortcuts className="ml-16 mr-16 mt-20" locale={country?.locale} />
        </Hydrator>
        <Hydrator whenVisible>
          <PromotionsBottom className="ml-16 mt-24" />
        </Hydrator>
        <Hydrator whenVisible>
          <Promotions className="ml-16 mt-12" />
        </Hydrator>
        <Hydrator whenVisible>
          <CbtTaxesFcUsSummary />
        </Hydrator>
        <Hydrator whenVisible>
          <CpgSummary />
        </Hydrator>
        <Hydrator whenVisible>
          <PaymentSummary />
        </Hydrator>
        <Hydrator whenVisible>
          <CbtSummary />
        </Hydrator>
        <Hydrator whenVisible>
          <GenericBannerPrice className="mb-16" />
          <GenericBannerInstallments className="mb-16" />
        </Hydrator>
        <Hydrator whenVisible>
          <UpsellingMeliplusCashback />
        </Hydrator>
        <Hydrator whenVisible>
          <CouponSummary />
        </Hydrator>
        <Hydrator whenVisible>
          <InstallationService />
        </Hydrator>
        <div className="ui-pdp-container__row--shipping-chunk mt-24">
          <Hydrator whenVisible>
            <MeliplusLabel className="ui-pdp-meliplus-label pl-16 pr-16" />
          </Hydrator>
          <Hydrator whenVisible>
            <SpecialShippingSummary />
          </Hydrator>
          <Hydrator whenVisible>
            <DynamicComponentWrapper renderDynamicComponent={renderTopShipping} spot="top_shipping" />
          </Hydrator>
          <Hydrator whenVisible>
            <SecondShippingSummary />
          </Hydrator>
          <Hydrator whenVisible>
            <UpsellingMeliplusSummary />
          </Hydrator>
          <Hydrator whenVisible>
            <GenericBanner className="mb-16" />
          </Hydrator>
          <Hydrator whenVisible>
            <PickUpSummary />
          </Hydrator>
          <Hydrator whenVisible>
            <CbtTaxesSummary />
          </Hydrator>
          <Hydrator whenVisible>
            <ReturnsSummary />
          </Hydrator>
        </div>
        <Hydrator whenVisible>
          <ItemStatusShortDescription />
        </Hydrator>
        <Hydrator whenVisible>
          <Seller />
        </Hydrator>
        <Hydrator whenVisible>
          <CompatsWidget position="below_seller" default />
        </Hydrator>
        <div className="ui-pdp-container__row--stock-and-full">
          <Hydrator whenVisible>
            <StockInformation />
          </Hydrator>
          <Hydrator whenVisible>
            <FulfillmentInformation />
          </Hydrator>
        </div>
        <Hydrator whenVisible>
          <TopAvailableQuantitySummary />
        </Hydrator>
        <Hydrator whenVisible>
          <BulkSaleCalculator />
        </Hydrator>
        <Hydrator whenVisible>
          <AvailableQuantity />
        </Hydrator>
        <Hydrator whenVisible>
          <MoqInformation />
        </Hydrator>
        <Hydrator whenVisible>
          <PaypalTooltip />
        </Hydrator>
        <Hydrator whenVisible>
          <ReturnsNotificationUp />
        </Hydrator>
        <Hydrator whenVisible>
          <MainActions />
        </Hydrator>
        <Hydrator whenVisible>
          <ReturnsNotificationBottom />
        </Hydrator>
        <Hydrator whenVisible>
          <Reputation />
        </Hydrator>
        <Hydrator whenVisible>
          <SellerExperiment className="mt-24 mr-16" />
        </Hydrator>
        <Hydrator whenVisible>
          <CartActions />
        </Hydrator>
        <Hydrator whenVisible>
          <CreditsTooltip />
        </Hydrator>
        <Hydrator whenVisible>
          <Reputation />
        </Hydrator>
        <Hydrator whenVisible>
          <ShippingCartBenefitsTooltip />
        </Hydrator>
        <Hydrator whenVisible>
          <LoyaltyFreeShippingTooltip />
        </Hydrator>
        <Hydrator whenVisible>
          <BuyBenefits />
        </Hydrator>
        <Hydrator whenVisible>
          <GiftRegistry className="mt-24" />
          <GroupedShareBookmark />
        </Hydrator>
        <Hydrator whenVisible>
          <HighlightedSpecsAttrs />
        </Hydrator>

        {/* Recommendations */}
        <Hydrator whenVisible>
          <RecommendationsCombos />
        </Hydrator>
        <Hydrator whenIdle>
          <RecommendationsCarouselByType
            featureName="carousel_v2p"
            className="ui-box-component ui-box-component-carousel-by-type"
          />
        </Hydrator>
        <Hydrator whenIdle>
          <RecommendationsCarouselFree
            featureName="carousel_up_web"
            className="ui-box-component ui-box-component-carousel-free"
          />
        </Hydrator>
        <Hydrator whenIdle>
          <RecommendationsCarouselFree
            featureName="carousel_above_pads"
            className="ui-box-component ui-box-component-carousel-free"
          />
        </Hydrator>
        <Hydrator whenIdle>
          <RecommendationsListDouble
            featureName="carousel_above"
            className="ui-box-component ui-box-component-list-double"
          />
        </Hydrator>

        {/* Features, Merch, Description, Returns & Box Components */}
        <Hydrator whenVisible>
          <SellerInfo />
        </Hydrator>
        {position === 'c' && (
          <Hydrator whenVisible>
            <ReviewsCapability />
          </Hydrator>
        )}
        <SellerData className="ui-box-component ui-box-component-seller-data" />
        <Hydrator whenVisible>
          <MerchEcosystem />
        </Hydrator>

        {/* Fashion */}
        <Hydrator whenVisible>
          <ApparelSizeSpecsPreview />
        </Hydrator>
        <Hydrator whenVisible>
          <HighlightedSpecsAttrsThirdSwap />
        </Hydrator>

        <Hydrator whenVisible>
          <HighlightedSpecsAttrsSwap />
        </Hydrator>
        <Hydrator whenVisible>
          <AllFeatures />
        </Hydrator>
        {position === 'b' && (
          <Hydrator whenVisible>
            <ReviewsCapability />
          </Hydrator>
        )}
        <Hydrator whenVisible>
          <Description className="pl-16 pr-16" />
        </Hydrator>

        <Hydrator whenVisible>
          <DynamicComponentWrapper
            renderDynamicComponent={spotMiddleMobileRender({
              firstChildClassName: 'ui-pdp-with--separator-top ui-pdp-with--separator-top--full mb-40',
              lastChildClassName: 'mt-40 mb-40',
              middleChildClassName: 'mt-40 mb-40',
            })}
            className="ui-vip-core-spot-middle"
            spot="spot_middle"
          />
        </Hydrator>

        <Hydrator whenVisible>
          <Returns />
        </Hydrator>
        <Hydrator whenVisible>
          <Warranty />
        </Hydrator>
        {/* Payment Methods, Questions, Reviews */}
        <Hydrator whenVisible>
          <PaymentMethods />
        </Hydrator>
        <Hydrator ssrOnly>
          <BillboardAdnUp />
        </Hydrator>
        <Hydrator whenVisible>
          <QADB />
        </Hydrator>
        <Hydrator whenVisible>
          <Questions />
        </Hydrator>
        <Hydrator whenVisible>
          <Reviews />
        </Hydrator>
        {position === 'a' && (
          <Hydrator whenVisible>
            <ReviewsCapability />
          </Hydrator>
        )}
        <Hydrator whenIdle>
          <RecommendationsCarouselFree
            featureName="carousel_vis_cross_selling_acc"
            className="ui-pdp-vis-carousel-b2c"
            lazy={false}
            preload
            fetchPriority="high"
            criticalImages={1}
            paddingHorizontal={20}
          />
        </Hydrator>
        <Hydrator whenVisible>
          <OfficialStores />
        </Hydrator>
        {/* Content Bottom */}
        <div className="ui-pdp-container--bottom">
          <Hydrator whenVisible>
            <Denounce />
          </Hydrator>
          <Hydrator whenIdle>
            <DynamicComponentWrapper
              renderDynamicComponent={featureName => (
                <RecommendationsCarouselFree
                  className="mb-24 mt-16"
                  featureName={featureName}
                  overWhiteBackground={false}
                />
              )}
              spot="carousel_bottom"
            />
          </Hydrator>
          <Hydrator whenIdle>
            <FullscreenAd />
          </Hydrator>
          <Hydrator ssrOnly>
            <BillboardAdn />
          </Hydrator>
          <Hydrator whenIdle>
            <RecommendationsCarouselByType featureName="grid_v2p" className="ui-pdp-carousel-grid-bottom" />
          </Hydrator>
          <Hydrator whenVisible>
            <RelatedSearches />
          </Hydrator>
          <Hydrator whenVisible>
            <Breadcrumb mode="compact" />
          </Hydrator>
          <Hydrator ssrOnly>
            <ExtendedMenu />
          </Hydrator>
        </div>
      </Grid.Container>
      <Hydrator whenIdle>
        <Snackbar />
      </Hydrator>
    </View>
  );
};

CoreMobile.propTypes = {
  initialState: shape().isRequired,
  country: shape({ locale: string }),
};

export default CoreMobile;
